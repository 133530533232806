// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-bots-mdx": () => import("./../../../../src/bots.mdx" /* webpackChunkName: "component---src-bots-mdx" */),
  "component---src-changelog-mdx": () => import("./../../../../src/changelog.mdx" /* webpackChunkName: "component---src-changelog-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-map-mdx": () => import("./../../../../src/map.mdx" /* webpackChunkName: "component---src-map-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-players-mdx": () => import("./../../../../src/players.mdx" /* webpackChunkName: "component---src-players-mdx" */),
  "component---src-poles-mdx": () => import("./../../../../src/poles.mdx" /* webpackChunkName: "component---src-poles-mdx" */),
  "component---src-shop-mdx": () => import("./../../../../src/shop.mdx" /* webpackChunkName: "component---src-shop-mdx" */),
  "component---src-stats-menu-mdx": () => import("./../../../../src/stats-menu.mdx" /* webpackChunkName: "component---src-stats-menu-mdx" */)
}

